<template>
  <div class="p-6">
    <div class="w-full flex justify-end py-4">
      <button
        type="button"
        @click="openModal({}, 'new')"
        class="
          inline-flex
          items-center
          px-2.5
          py-1.5
          border border-transparent
          text-xs
          font-medium
          rounded
          shadow-sm
          text-white
          bg-fuchsia-600
          hover:bg-fuchsia-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-fuchsia-500
        "
      >
        new planning
      </button>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-fuchsia-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    {{ $t("Category") }}
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    +/-
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    {{ $t("amount in Euro") }}
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    {{ $t("Person") }}
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    {{ $t("Due date") }}
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">{{ $t("Edit") }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(plan, planIdx) in noteList"
                  :key="plan.id"
                  :class="planIdx % 2 === 0 ? 'bg-white' : 'bg-fuchsia-50'"
                >
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ plan.category ? plan.category.name : "" }}
                  </td>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{
                      plan.category
                        ? plan.category.cat_in_out
                          ? "+"
                          : "-"
                        : ""
                    }}
                  </td>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ plan.amount }}
                  </td>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ plan.person ? plan.person.name : "" }}
                  </td>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ plan.repetition_e }}
                  </td>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
                  >
                    <div class="flex justify-end space-x-2">
                      <button>
                        <PencilAltIcon
                          class="w-4 text-fuchsia-600"
                          @click="openModal(plan, 'update')"
                        />
                      </button>
                      <button>
                        <TrashIcon
                          class="w-4 text-red-400"
                          @click="deletePlan(plan.id)"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modal
      :open="showModal"
      @close="showModal = false"
      :type="type"
      :data="note"
      :mode="mode"
    ></modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/outline";

export default {
  components: { PencilAltIcon, TrashIcon, Modal },
  data() {
    return {
      showModal: false,
      type: null,
      note: null,
      mode: null,
    };
  },
  computed: {
    noteList() {
      return this.$store.state.note.noteList.data;
    },
  },
  methods: {
    openModal(note, mode) {
      this.$store.dispatch("User/GetCategory").then(() => {
        this.$store.dispatch("User/getPersons").then(() => {
          this.showModal = true;
          this.note = note;
          this.mode = mode;
          this.type = "PlanningModal";
        });
      });
    },
    getNoteList(data) {
      this.$store.dispatch("note/getNoteList", data);
    },
    deletePlan(id) {
      this.$store.dispatch("note/DeletePlan", id);
    },
  },
  mounted() {
    this.getNoteList();
  },
};
</script>

<style></style>
